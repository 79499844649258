<template>
  <div class="page">
    <div class="img-box">
      <img src="../../static/image/ic_error@2x.png" alt="error" class="error-img">
    </div>
    <p class="error-tip">404 not found</p>
  </div>
</template>

<script>
export default {
  name: "Error"
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(@color-background);
}

.img-box {
  .wh(100%, 3.8rem);
}

.error-img {
  display: block;
  .wh(2.20rem, 2.20rem);
  margin: .80rem auto;
}

.error-tip {
  .sc(@fontsize-large-xx, @color-grey);
  line-height: .40rem;
  text-align: center;
}
</style>
